import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import Advert from '../atoms/advert';

const mobileSizes = [
  [320, 50],
  [320, 1],
];
const desktopSizes = [
  [320, 50],
  [728, 90],
  [1280, 100],
];
class StickyAdvert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      timeReached: false,
      display: false,
    };
    this.dbLoadAd = debounce(this.loadAd.bind(this), 50);
  }

  componentDidMount() {
    this.dbLoadAd();
    window.addEventListener('scroll', this.dbLoadAd);
  }
  shouldComponentUpdate() {
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.dbLoadAd);
  }
  showAdByPosition(loadingPosition) {
    let shouldShowAd = false;
    // To show the sticky ad after loading a specific dispaly ad
    const adTarget = global.document.getElementById(`br-ad-${loadingPosition}`);
    if (adTarget) {
      const adPosition = adTarget.getBoundingClientRect();
      // Added height to the bottom to prevent overlaying adTarget by sticky ad
      const triggerLocation = adPosition.bottom + adPosition.height;
      shouldShowAd = triggerLocation <= innerHeight;
    }
    return shouldShowAd;
  }
  loadAd() {
    const {timeReached} = this.state;
    const {loadingPosition, isMobileDevice, hideCallback} = this.props;
    let shouldShowAd = false;
    if (!isMobileDevice && loadingPosition) {
      shouldShowAd = !timeReached ? this.showAdByPosition(loadingPosition) : true;
    } else {
      shouldShowAd = scrollY > 400;
    }
    if (shouldShowAd) {
      if (!timeReached) {
        if (!this.state.display) {
          this.setState({display: true});
          if (hideCallback) hideCallback(true);
          setTimeout(() => {
            this.setState({display: false, timeReached: true}, () => {
              if (hideCallback) hideCallback(false);
              window.removeEventListener('scroll', this.dbLoadAd);
            });
          }, 5000);
        }
      }
    }
  }
  hide = () => {
    this.setState({display: false, timeReached: true});
    if (this.props.hideCallback) this.props.hideCallback(false);
  };
  render() {
    const {position, id, responsiveSize, reload} = this.props;
    const classes = classnames(this.props.classes, {
      atom: true,
      stickyAdvert: true,
      hide: !this.state.display,
    });
    const adSizes = this.props.isMobileDevice ? mobileSizes : desktopSizes;
    return (
      <div className={classes}>
        <Advert
          country={this.props.country}
          position={position}
          id={id}
          isVisitorOnMobile={this.props.isMobileDevice}
          responsiveSize={
            !this.props.isMobileDevice ? responsiveSize : responsiveSize.concat('_mobile')
          }
          reload={reload}
          size={adSizes}
          closeButton={this.props.closeButton}
          hide={this.hide}
        />
      </div>
    );
  }
}

export default StickyAdvert;
